import React from 'react';
import ImageGallery from 'react-image-gallery';
import './WeddingStudio.css';

function Portraits () {
  const images = [
    {
      original: '/portraits/DSC_9555.JPG',
      thumbnail: '/portraits/DSC_9555.JPG'
    },
    {
      original: '/portraits/DSC_8975.JPG',
      thumbnail: '/portraits/DSC_8975.JPG'
    },
    {
      original: '/portraits/5.jpg',
      thumbnail: '/portraits/5.jpg'
    },
    {
      original: '/portraits/DSC_3287.JPG',
      thumbnail: '/portraits/DSC_3287.JPG'
    },
    {
      original: '/portraits/DSC_4622.JPG',
      thumbnail: '/portraits/DSC_4622.JPG'
    },
    {
      original: '/portraits/DSC_3549.JPG',
      thumbnail: '/portraits/DSC_3549.JPG'
    },
    {
      original: '/portraits/4.jpg',
      thumbnail: '/portraits/4.jpg'
    },
    {
      original: '/portraits/port.jpg',
      thumbnail: '/portraits/port.jpg'
    },
    {
      original: '/portraits/DSC_3379.JPG',
      thumbnail: '/portraits/DSC_3379.JPG'
    },
    {
      original: '/portraits/1.jpg',
      thumbnail: '/portraits/1.jpg'
    },
    {
      original: '/portraits/DSC_3572.JPG',
      thumbnail: '/portraits/DSC_3572.JPG'
    }
  ]
  return (
    <div>
    <ImageGallery items={images} />
    </div>
  );
}

export default Portraits;
