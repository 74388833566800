import React from 'react';
import BackgroundSlider from 'react-background-slider';

function Homepage () {
  const images = [
    '/homepage/DSC_56322.JPG',
    '/homepage/DSC_94662.JPG',
    '/homepage/DSC_25132.JPG',
    '/homepage/DSC_87432.JPG',
    '/homepage/DSC_37512.JPG',
    '/homepage/2.jpg'
  ]
  return (
    <BackgroundSlider images={images}
    duration={6}
    transition={0.5} />
  );
}

export default Homepage;
