import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import './i18n';

const Loader = () => <div>Loading...</div>;

ReactDOM.render(<Suspense fallback={<Loader />}><App /></Suspense>, document.getElementById('root'));

serviceWorker.unregister();
