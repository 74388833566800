import React from 'react';
import ImageGallery from 'react-image-gallery';
import './WeddingStudio.css';

function WeddingStudio () {
  const images = [
    {
      original:'/studioW/DSC_1363.jpg',
      thumbnail: '/studioW/DSC_1363.jpg'
    },
    {
      original: '/studioW/DSC_8083.jpg',
      thumbnail: '/studioW/DSC_8083.jpg'
    },
    {
      original: '/studioW/DSC_3526.jpg',
      thumbnail: '/studioW/DSC_3526.jpg'
    },
    {
      original: '/studioW/DSC_9004.jpg',
      thumbnail: '/studioW/DSC_9004.jpg'
    },
    {
      original: '/studioW/DSC_9939.jpg',
      thumbnail: '/studioW/DSC_9939.jpg'
    }
  ]
  return (
    <div>
    <ImageGallery items={images} />
    </div>
  );
}

export default WeddingStudio;
