import React from "react";
import "./style.css";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

const DesktopNavbar = ({changeLanguage, language}) => {
  const { t } = useTranslation();

  const links = [
    { name: t("weddings-outdoor"), to: "/wedding-outdoor" },
    { name: t("weddings-studio"), to: "/wedding-studio" },
    { name: t("portraits type"), to: "/portraits" },
    { name: t("maternity type"), to: "maternity" },
    { name: t("newborn type"), to: "newborn" }
  ];

  return (
    <div className="DesktopNavbar = " style={{backgroundImage: window.location.pathname !== '/' && 'unset'}}>
      <Link style={{letterSpacing: language === 'ar' && 'unset'}} className="desktop-link" to="/">
        <img className="img" src="/LOGO2.png" alt="logo" />
      </Link>
      <span className="line"></span>
      {/* <div className="nav-links"> */}
        <Link style={{letterSpacing: language === 'ar' && 'unset'}} className="desktop-link" to="/about">{t("about us")}</Link>
        <div className="gallery">
          <button style={{letterSpacing: language === 'ar' && 'unset'}} className="gallery-btn">{t("gallery")}</button>
          <div className="gallery-menu">
            {links.map((link, index) => {
              const { to, name } = link;
              return (
                <Link style={{letterSpacing: language === 'ar' && 'unset'}} className="desktop-link" to={to} key={index}>
                  {name}
                </Link>
              );
            })}
          </div>
        </div>
        <Link style={{letterSpacing: language === 'ar' && 'unset'}} className="desktop-link" to="/blog">{t("blog")}</Link>
        <Link style={{letterSpacing: language === 'ar' && 'unset'}} className="desktop-link" to="/booking">{t("booking")}</Link>
        <div className='App-header-desktop'>
           {language === 'ar' && <button className='change-language-desktop' onClick={() => changeLanguage('en')}>en</button>}
           {language === 'en' && <button className='change-language-desktop' onClick={() => changeLanguage('ar')}>ع</button>}
         </div>
      {/* </div> */}
    </div>
  );
};

export default DesktopNavbar;
