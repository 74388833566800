import React from 'react';
import { useTranslation } from "react-i18next";

import './Popup.css';

function Popup({setShowPopup}) {
    const { t } = useTranslation();

    return (
        <div className="overlay" onClick={() => setShowPopup(false)}>
            <div className="popup-container">
                <h1 className="popup-title">{t('thank you')}</h1>
                <p className="popup-description">{t('popup description')}</p>
                <button className="popup-button" onClick={() => setShowPopup(false)}>{t('back to homepage')}</button>
            </div>
        </div>
        )
}


export default Popup;
