import React from 'react';
import './About.css';
import { useTranslation, Trans } from 'react-i18next';


function About () {

  const { t, i18n } = useTranslation();


  return (
    <div className='About'>

      <div className='block photographer-block'>

        <div className='block-text photographer-text'>
          <h2>{t('photographer name')}</h2>
          <hr />
          <p>{t('about photographer')}</p>
        </div>
        <img className='photographer-img' src='/DSC_83555.JPG'></img>
      </div>

      <div className='block studio-block'>
        <img className='studio-img' src='/DSC_82405.JPG'></img>
        <div className='block-text studio-text'>
          <h2>{t('the studio')}</h2>
          <hr />
          <p>{t('about studio')}</p>
        </div>
      </div>

    </div>
  );
}


export default About;
