export default {
  "about us": "ABOUT US",
  "gallery": "GALLERY",
  "blog": "BLOG",
  "booking": "BOOKING",
  "photographer name": "Heba Kasem",
  "about photographer": "I am Heba Kasem, the owner and founder of Al-Kasem Studio (2014). I was born and raised in the city of Tayibe, when today I am a proud mother of two wonderful daughters. My professional career started by acquiring a photography degree in the Bezalel Academy of Arts in Jerusalem, and ever since I have equipped myself with various professional certificates and workshops. Photography is my artistic passion in life. A sphere promoting my own unique story. To me, a picture is a moment that froze in time, to remain infinitely as an unforgettable memory. Allow me to creat that frozen moment for you.",
  "the studio": "The Studio",
  "about studio": "Al- Kasem Studio, located in the city of Tayibe, is a private studio in an old building that has been affiliated with the Kasem family for the past 40 years. The studio was established by the photographer Heba Kasem, where the idea of a mobile studio for Tayibe’s young generation served as her university graduation project. The studio upgrade was a significant leap to demonstrate the value of modern photography and its impact on society today. The initial idea drove the photography of Tayibe’s young generation in their personal environment and space. With the passage of time and the incremental pace in the number of attendes, the studio embraced more individuals as well as their families and beautiful moments, allowing Heba to document them distinctly within her studio. The studio contains a very large garden where the past and present harmonize with it and is reflected in the impact of the place and the memories of the ancestors from previous generations.",
  "first blog paragraph": "PROJECT - NA'NA' BANI SA'AB",
  "mid blog paragraph": "Na'na' Bani Sa'ab  is a photography collection featuring the veteran generation in Taybih",
  "last blog paragraph": "The process involved use the of a mobile studio which travelled to various neighborhoods in Taybih. its subjects are featured within their own space and amongst their treasured belongings. each figure chose a specific from of natural light  , in some cases the light entered through the crack of an open door or via a window. In efforts to prevent the dissipation of passion and the attachment to arts , it was necessary to identify the cultural and academic knowledge that support, sustain and deepen the roots and long-term  relevance of the artwork.",
  "booking h2": "READY TO BOOK YOUR NEXT SESSION?",
  "booking h3": "Let's get the process started",
  "name placeholder": "Full name",
  "phone placeholder": "Phone number",
  "date placeholder": "Date of session",
  "type of session": "Type of session",
  "wedding type": "wedding",
  "weddings-outdoor": "weddings - outdoor",
  "weddings-studio": "weddings - studio",
  "portraits type": "portraits",
  "maternity type": "maternity",
  "newborn type": "newborn",
  "notes placeholder": "Tell us more",
  "submit error": "Something went wrong, please try again!!",
  "thank you": "Thank you",
  "popup description": "We'll be in touch with you within 48 hours for further details",
  "back to homepage": "Back to Homepage",
  "submit": "SUBMIT",
  "booking description": "Please provide the relevant date of booking, we will call you back",
  "Phone number error": "Phone number must contain  10 digits only",
  "missing details": "Missing Details",
  "loading": "Loading..."
}
