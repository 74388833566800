import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import { stack as Menu } from "react-burger-menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {useHistory} from 'react-router-dom'

import {
  faInstagram,
  faFacebook,
  faWaze
} from "@fortawesome/free-brands-svg-icons";

import "./style.css";

const MobileNav = ({language, changeLanguage}) => {
  const [showHeight, setShowHeight] = useState(false)
  const { t } = useTranslation();
  const history = useHistory()

  return (
    <div
      className="mobile-navbar-wrapper"
      style={{marginBottom: history.location.pathname === '/' && 'unset'}}
    >
          <div className='App-header'>
           {language === 'ar' && <button className='change-language' onClick={() => changeLanguage('en')}>en</button>}
           {language === 'en' && <button className='change-language' onClick={() => changeLanguage('ar')}>ar</button>}
         </div>
      <Link to="/">
        <img className="mobile-img" src="/LOGO2.png" alt="logo" />
      </Link>
      <Menu right width={350}>
        <a id="about" className="menu-item" href="/about">
          {t("about us")}
        </a>
        <a id="blog" className="menu-item" href="/blog">
          {t("blog")}
        </a>
        <a id="booking" className="menu-item" href="/booking">
          {t("booking")}
        </a>
        <div className="collapse" style={{height: showHeight && '600px'}} onClick={() => setShowHeight(!showHeight)}>
          <a
            id="gallery"
            className="menu-item"
            // style={{borderBottom: '0'}}
            href="#"
            onClick={() => setShowHeight(!showHeight)}
          >
            {t("gallery")}
          </a>
          <a id="wedding-outdoor" className="menu-item" href="/wedding-outdoor">
            {t("weddings-outdoor")}
          </a><a id="wedding-studio" className="menu-item" href="/wedding-studio">
            {t("weddings-studio")}
          </a><a id="portraits" className="menu-item" href="/portraits">
            {t("portraits type")}
          </a><a id="maternity" className="menu-item" href="/maternity">
            {t("maternity type")}
          </a><a id="newborn type" className="menu-item" href="/newborn">
            {t("newborn")}
          </a>
        </div>
        <div className="social-media">
          <a
            href="https://www.instagram.com/alkasem.studio/"
            className="instagram-social social-menu-item"
          >
            <FontAwesomeIcon icon={faInstagram} size="2x" />
          </a>
          <a
            href="https://www.facebook.com/Alkasemstudio/"
            className="facebook-social social-menu-item"
          >
            <FontAwesomeIcon icon={faFacebook} size="2x" />
          </a>
          <a
            href="https://www.waze.com/ul?preview_venue_id=22937923.229444762.183326&navigate=yes"
            className="waze-social social-menu-item"
          >
            <FontAwesomeIcon icon={faWaze} size="2x" />
          </a>
        </div>
      </Menu>
    </div>
  );
};

export default MobileNav;
