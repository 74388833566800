import React from "react";

import MobileNavBar from "./mobile";
import DesktopNavBar from "./desktop";
import MediaQuery from "../MobileMediaQuery";

const NavBar = ({language, changeLanguage}) => {
  return (
    <MediaQuery>
      {isMobile => (isMobile ? 
        <MobileNavBar language={language} changeLanguage={changeLanguage}/> 
      : <DesktopNavBar language={language} changeLanguage={changeLanguage}/>
      )}
    </MediaQuery>
  );
};

export default NavBar;
