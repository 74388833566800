export default {
  "about us": "نـحـن",
  "gallery": "صـور",
  "blog": "مدونة",
  "booking": "للحـجـز",
  "photographer name": "هبه قاسم",
  "about photographer": `

اهلا بكم في موقع الخاص باستوديو ال قاسم
انا هبه قاسم صاحبة ومؤسسة ستوديو ال قاسم من سنة 2014 .
ام لطفلتين رائعتين ، أعيش في الطيبة من مواليد 1991.
درست اللقب الاول بالتصوير في اكاديمية بتسلئيل للفنون في القدس.
لم أكنفي يوما بشهادة الجامعية فقط ، تعلمت ولا ازلت اتعلم ايضا العديد من الورشات التصويرية بعدة مجالات مهنية وتربوية .

التصوير هو شغفي الفني في الحياة ، من خلال عدستي احب ان ابز وأوثق اجمل االحظات والتفاصيل المهمة في الحياة ، اني ارى العالم من زاويتي ومنظوري الفني في الحياة.
التصوير عالم ، ولي حكايتي الخاصة في هذا العالم.
الصورة عبارة عن لحظة تجمدت من الزمن، لتبقى ذكرى الى الابد ، وهذه اللحظة لها معنى كبير وذكرى لا تنتسى .`,
  "the studio": "الاستوديو",
  "about studio": "ستوديو ال قاسم بالطيبة هو ستوديو خاص داخل مبنى قديم يتبع اصلة لال قاسم منذ سنوات ال٤٠ . أستحدث (اقيمت اعمال ترميمية) الاستوديو على يد المصورة هبه قاسم وهي خريجة لقب اول بالتصوير حيث نشأت فكرة ستوديو متنقل للجيل المخضرم بالطيبة لمشروعها التخرج في الجامعة.كان تحديث الاستوديو هو قفزة كبيرة لاثبات قيمة التصوير الحديث وتؤثيره ع المجتمع اليوم ، بحيث بدأت فكرته بتصوير اشخاص من الجيل المخضرم في الطيبة في حيزهم وبيتهم الشخصي ، لكن مع مرور الوقت وازدياد العدد فلجأت المصورة لاستقبال واحتضان الشخصيات الكبيرة وأيضا عائلاتهم ولحظاتهم الجميلة لتوثيقها بصورة مميزة داخل الاستوديو الخاص بها. يحوي الاستوديو على حديقة كبيرة جدا حيث يتناغم بها الماضي والحاضر وينعكس على أثر المكان وذكريات الاجداد من السنوات السابقة.",
  "first blog paragraph":"مـشـروع - نـعـنـع بـنـي صـعـب",
  "mid blog paragraph": "أرشيف نعنع بني صعب هو مجموعة من الصور الفوتوغرافية لأبناء الجيل المخضرم من الطيبة .",
  "last blog paragraph": `
  أرشيف نعنع بني صعب هو مجموعة من الصور الفوتوغرافية لأبناء الجيل المخضرم من الطيبة .


سيرورة الارشفة كانت أشبه بالاستوديو المتنقل بين حارات بلدة الطيبة والتقاط الصور للناس في حيزهم الشخصي بين أشياءهم العزيزة , ووسط خيوط الضوء الي اعتادوا دخولها كل يوم عبر النافذة او من شق الباب الموارب.

" حتى لا يتبدد الشغف والتغلق بهذا الفن , كان لا بد له من ركائز معرفية ثقافية اكاديمية وتطيل أمده ومداه وتعمق جذوره حتى يعيش طويلا ويثمر كثيرا .
`,
  "booking h2": "هل اعجبك عمل الاستوديو؟",
  "booking h3": "جـاهـز الان لـلـحـجـز؟",
  "name placeholder": "الاسم الكامل",
  "phone placeholder": "رقم الهاتف",
  "date placeholder": "تاريخ الجلسة",
  "type of session": "نوع الجلسة",
  "wedding type": "زفاف",
  "weddings-outdoor": "زفاف - خارج الاستوديو",
  "weddings-studio": "زفاف - داخل الاستوديو",
  "portraits type": "صور ذاتية",
  "maternity type": "حمل",
  "newborn type": "مولود جديد",
  "notes placeholder": "تفاصيل اكثر",
  "submit error": "حدث خطأ ما. أعد المحاولة من فضلك!!",
  "thank you": "شكرا",
  "popup description": " سنتواصل معكم خلال 48 ساعة للمزيد من المعلومات",
  "back to homepage": "عد الى الصفحة الرئيسية",
  "submit": "ارسـال",
  "booking description": 'الرجاء وضع التاريخ المعنيين به للحجز  ، نأمل ان يكون متاح ، نعاود الاتصال بكم',
  "Phone number error": "رقم الهاتف يجب ان يحتوي على ١٠ ارقام فقط",
  "missing details" : "تفاصيل ناقصة",
  "loading": "...جاري التحميل"
}
