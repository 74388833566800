import React from 'react';
import './Booking.css';
import {useHistory} from 'react-router-dom';
// import {Button, Form, div, label, input, FormText} from 'reactstrap';
import axios from 'axios';

import { useTranslation, Trans } from 'react-i18next';

const Booking = (props) => {
  const history = useHistory();
  const [name, setName] = React.useState('')
  const [phone, setPhone] = React.useState('')
  const [date, setDate] = React.useState('')
  const [session, setSession] = React.useState('')
  const [notes, setMore] = React.useState('')
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [missing, setMissing] = React.useState(false)
  const formEl = React.useRef()

  const resetForm = () => {
    setName('')
    setPhone('')
    setDate('')
    setSession('')
    setMore('')
    setLoading(false)
    setMissing(false)
  }

  const { t, i18n } = useTranslation();

  const handleSubmit = async (e) => {

    e.preventDefault()
    setLoading(true)

    if (phone.length !== 10) {
      setError (t('Phone number error'))
      return setLoading(false)
    } else if (!name || !date || !session) {
      setError(t('missing details'))
       setMissing(true)
      return setLoading(false)
    }

    setMissing(false)
    setError('')

    const form =  await axios.post('/api/form', {
      name,
      phone,
      date,
      session,
      notes
    })

    if(form.data == 'success'){
      // formEl.current.reset();
      props.setShowPopup(true)
      resetForm()
      history.push('/');
    } else {
      setError('submit error');
    }
  }

    return (

    <div>
      <h2 className='form-h2'>{t('booking h2')}</h2>
      <h3 className='form-h3'>{t('booking h3')}</h3>
      <h3 className='form-h3'>{t('booking description')}</h3>
      <form innerRef = {formEl} className='form'>
        <div className='upper-form'>
          <div className='input-container'>
            <label for='name'>{t('name placeholder')}</label>
            <input
              className= 'input'
              type = 'text'
              name = 'fullname'
              required
              style={{borderColor: (missing && !name) && 'red'}}
              onChange = {e => setName(e.target.value)} />
          </div>
          <div className='input-container'>
          <label for='phone'>{t('phone placeholder')}</label>
            <input
              className= 'input'
              type = 'text'
              name = 'phone-number'
              required
              style={{borderColor: (missing && !phone) && 'red'}}
              onChange = {e => setPhone(e.target.value)} />
          </div>
          <div className='input-container'>
          <label for='date'>{t('date placeholder')}</label>
            <input className= 'input'
              type = 'date'
              name = 'session-date'
              required
              style={{borderColor: (missing && !date) && 'red'}}
              onChange = {e => setDate(e.target.value)} />
          </div>
          <div className='input-container'>
            <label for='session'>{t('type of session')}</label>
            <select
              className= 'input'
              name = 'session-type'
              onChange = {e => setSession(e.target.value)}
              style={{borderColor: (missing && !session) && 'red'}}
              required
            >
              <option selected ></option>
              <option>{t('wedding type')}</option>
              <option>{t('portraits type')}</option>
              <option>{t('maternity type')}</option>
              <option>{t('newborn type')}</option>
            </select>
          </div>
        </div>
        <div className='textarea-container'>
          <label for='notes'>{t('notes placeholder')}</label>
          <input
            className = 'input textarea'
            type = 'textarea'
            name = 'notes-text'
            onChange = {e => setMore(e.target.value)} />
        </div>
        {!loading
        ? <button onClick={handleSubmit} className='submit'>{t("submit")}</button>
        : <span style={{marginTop: '20px', marginBottom: '20px'}}>{t("loading")}</span>}
        {error && t(error) }
      </form>
    </div>
  );
}

export default Booking;
