import React from 'react';
import ImageGallery from 'react-image-gallery';
import './WeddingStudio.css';

function Maternity () {
  const images = [
    {
      original: '/maternity/DSC_83962.JPG',
      thumbnail: '/maternity/DSC_83962.JPG'
    },
    {
      original: '/maternity/DSC_23042.JPG',
      thumbnail: '/maternity/DSC_23042.JPG'
    },
    {
      original: '/maternity/DSC_21582.JPG',
      thumbnail: '/maternity/DSC_21582.JPG'
    },
    {
      original: '/maternity/DSC_01012.JPG',
      thumbnail: '/maternity/DSC_01012.JPG'
    },
    {
      original: '/maternity/DSC_9139.JPG',
      thumbnail: '/maternity/DSC_9139.JPG'
    },
    {
      original: '/maternity/DSC_7280.JPG',
      thumbnail: '/maternity/DSC_7280.JPG'
    },
    {
      original: '/maternity/DSC_7428.JPG',
      thumbnail: '/maternity/DSC_7428.JPG'
    },
    {
      original: '/maternity/1224.jpg',
      thumbnail: '/maternity/1224.jpg'
    },
    {
      original: '/maternity/DSC_28332.JPG',
      thumbnail: '/maternity/DSC_28332.JPG'
    }



  ]
  return (
    <div>
    <ImageGallery items={images} />
    </div>
  );
}

export default Maternity;
