import React from 'react';
import ImageGallery from 'react-image-gallery';
function WeddingOutdoor () {
  const images = [
    {
      original: '/outdoorW/wed-stu3.jpg',
      thumbnail: '/outdoorW/wed-stu3.jpg'
    },
    {
      original: '/outdoorW/DSC_7183.JPG',
      thumbnail: '/outdoorW/DSC_7183.JPG'
    },
    {
      original: '/outdoorW/DSC_2575.JPG',
      thumbnail: '/outdoorW/DSC_2575.JPG'
    },
    {
      original: '/outdoorW/DSC_8713.JPG',
      thumbnail: '/outdoorW/DSC_8713.JPG'
    },
    {
      original: '/outdoorW/121.jpg',
      thumbnail: '/outdoorW/121.jpg'
    },
    {
      original: '/outdoorW/DSC_7539.JPG',
      thumbnail: '/outdoorW/DSC_7539.JPG'
    },
    {
      original: '/outdoorW/DSC_6748.JPG',
      thumbnail: '/outdoorW/DSC_6748.JPG'
    },
    {
      original: '/outdoorW/DSC_3623.JPG',
      thumbnail: '/outdoorW/DSC_3623.JPG'
    },
    {
       original: '/outdoorW/DSC_1540.JPG',
      thumbnail: '/outdoorW/DSC_1540.JPG'
    }
  ]
  return (
    <div>
    <ImageGallery items={images} />
    </div>
  );
}

export default WeddingOutdoor;
