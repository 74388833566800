import React, {useState} from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Popup from "./components/Popup.js";

import { useTranslation } from "react-i18next";

import Navbar from "./components/NavBar/";
import Footer from "./components/Footer.js";
import Homepage from "./pages/Homepage.js";
import Blog from './pages/Blog.js';
import About from "./pages/About.js";
import Booking from "./pages/Booking.js";
import WeddingOutdoor from "./pages/Gallery/WeddingOutdoor.js";
import WeddingStudio from "./pages/Gallery/WeddingStudio.js";
import Newborn from "./pages/Gallery/Newborn.js";
import Portraits from "./pages/Gallery/Portraits.js";
import Maternity from "./pages/Gallery/Maternity.js";

const App = () => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState('en')
  const [showPopup, setShowPopup] = useState(false)

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
    setLanguage(lng)
  };

  return (
    <div className="App" style={{direction: language === 'ar' ? 'rtl' : 'ltr'}}>
      <Router>
        <Navbar language={language} changeLanguage={changeLanguage}/>
         <div style={{ minHeight: '75vh'}}>
         <Switch>
            <Route exact path='/'>
              <Homepage />
            </Route>
            <Route path='/about'>
              <About />
            </Route>
            <Route path='/wedding-outdoor'>
              <WeddingOutdoor />
            </Route>
            <Route path='/wedding-studio'>
              <WeddingStudio />
            </Route>
            <Route path='/portraits'>
              <Portraits />
            </Route>
            <Route path='/maternity'>
              <Maternity />
            </Route>
            <Route path='/newborn'>
              <Newborn />
            </Route>
            <Route path="/blog">
              <Blog />
            </Route>
            <Route path="/booking">
            <Booking setShowPopup={setShowPopup} />
            </Route>
          </Switch>
        </div>
      </Router>
      {showPopup && <Popup setShowPopup={setShowPopup} />}
      <Footer/>
    </div>
  );
};

export default App;
