import React from 'react';
import './Footer.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInstagram, faFacebook, faWaze} from '@fortawesome/free-brands-svg-icons';

function Footer() {
  return (
    <div className='Footer'>
      <div>
        <h3>alkasem.studio@gmail.com | 0549949242 </h3>
      </div>
      <div className='social'>
        <a href='https://www.instagram.com/alkasem.studio/' className='instagram-social'>
        <FontAwesomeIcon icon={faInstagram} />
        </a>
        <a href='https://www.facebook.com/Alkasemstudio/' className='facebook-social'>
        <FontAwesomeIcon icon={faFacebook} />
        </a>
        <a href='https://www.waze.com/ul?preview_venue_id=22937923.229444762.183326&navigate=yes' className='waze-social'>
        <FontAwesomeIcon icon={faWaze} />
        </a>
      </div>
    </div>
  );
}

export default Footer;
