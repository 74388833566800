import React from 'react';
import './Blog.css';
import { useTranslation, Trans } from 'react-i18next';


function Blog () {

  const { t, i18n } = useTranslation();

  return (
    <div className='wrapper'>
      <div className='top-section'>
        <img src='DSC_2216.jpg' />
        <div className='top-section-text'>
          <p>{t('first blog paragraph')}</p>
        </div>
      </div>
      <div className='mid-section'>{t('mid blog paragraph')}</div>
      <div className='bottom-section'>
        <div className='bottom-section-text'>
          <p className="bottom-section-text-paragraph">{t('last blog paragraph')}</p>
        </div>
        <div className='bottom-section-images'>
          <div className='bottom-section-first-image'></div>
          <div className='bottom-section-second-image'></div>
          <div className='bottom-section-third-image'></div>
          <div className='bottom-section-forth-image'></div>
        </div>

      </div>
    </div>
  );
}

export default Blog;
