import React from 'react';
import ImageGallery from 'react-image-gallery';
import './WeddingStudio.css';

function Newborn () {
  const images = [
    {
      original: '/newborn/DSC_8133.JPG',
      thumbnail: '/newborn/DSC_8133.JPG'
    },
    {
      original: '/newborn/33445533.jpg',
      thumbnail: '/newborn/33445533.jpg'
    },
    {
      original: '/newborn/1212111.jpg',
      thumbnail: '/newborn/1212111.jpg'
    },
    {
      original: '/newborn/4563.jpg',
      thumbnail: '/newborn/4563.jpg'
    },
    {
      original: '/newborn/8.jpg',
      thumbnail: '/newborn/8.jpg'
    },
    {
      original: '/newborn/baby.jpg',
      thumbnail: '/newborn/baby.jpg'
    },
    {
      original: '/newborn/DSC_3354.JPG',
      thumbnail: '/newborn/DSC_3354.JPG'
    },
    {
      original: '/newborn/32323.jpg',
      thumbnail: '/newborn/32323.jpg'
    }

  ]
  return (
    <div>
    <ImageGallery items={images} />
    </div>
  );
}

export default Newborn;
