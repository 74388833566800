import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";

import translationEn from './en/translation.js';
import translationAr from './ar/translation.js';

// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .use(Backend)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: {
      en: {
        translation: translationEn
      },
      ar: {
        translation: translationAr
      }
    },

    lng: 'en',
    fallbackLng: 'en',
    debug: true,
  });

export default i18n;
